import builder from '@builder.io/react';
import Catalog from 'components/organisms/Catalog/Catalog';
import Layout from 'components/organisms/Layout/Layout';
import React from 'react';
import '../builderComponents/PLPBanner/PLPBanner';
import '../builderComponents/SeoContent/SeoContent';
import '../builderComponents/CategorySlider/CategorySlider';
import { graphql } from 'gatsby';
import Seo from 'components/organisms/Seo/Seo';

builder.init(process.env.BUILDER_PUBLIC_API);

const discounts = ({ data }) => {
  const headerContent = data && data.builder.onePlpBanner?.content;
  const footerContent = data && data.builder.onePlpBottomSection?.content;
  const seoContent = data && data.builder.onePlpBottomSeoContent?.content;

  return (
    <Layout>
      <Seo title='Special Offers - Pet Chemist Online' />
      <Catalog
        customFilter="sale_price > 0"
        category={{
          name: 'Special Offers'
        }}
        isBrand={true}
        headerContent={headerContent}
        footerContent={footerContent}
        seoContent={seoContent?.data}
      />
    </Layout>
  );
};

export default discounts;

export const query = graphql`
  query CategoryTemplateQuery($path: String!) {
    builder: allBuilderModels {
      onePlpBanner(target: { urlPath: $path }, options: { cachebust: true }) {
        content
      }
      onePlpBottomSection(
        target: { urlPath: $path }
        options: { cachebust: true }
      ) {
        content
      }
      onePlpBottomSeoContent(
        target: { urlPath: $path }
        options: { cachebust: true }
      ) {
        content
      }
    }
  }
`;
